// extracted by mini-css-extract-plugin
export var about = "layout-module--about--590ff";
export var active = "layout-module--active--4be22";
export var centered = "layout-module--centered--9c596";
export var container = "layout-module--container--78b04";
export var content = "layout-module--content--2bfc1";
export var contentWrapper = "layout-module--content-wrapper--d4cd1";
export var footer = "layout-module--footer--ad7d0";
export var gap = "layout-module--gap--3ddc4";
export var header = "layout-module--header--4755c";
export var heading = "layout-module--heading--f158c";
export var navLinkItem = "layout-module--nav-link-item--a5f0a";
export var navLinkText = "layout-module--nav-link-text--69cda";
export var navLinks = "layout-module--nav-links--1113b";
export var siteTitle = "layout-module--site-title--e4dea";
export var titleRow = "layout-module--title-row--4b369";