import * as React from 'react'
/*import { StaticImage } from 'gatsby-plugin-image'*/
import Layout from '../components/layout'
import { centered, contentWrapper, heading } from '../components/layout.module.css';
import { Seo } from '../components/seo'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <h1 className={`${heading} ${centered}`}>Hello</h1>
      <div className={`${contentWrapper} ${centered}`}>
        <h2>I'm a work in progress!</h2>
        <p>though, if you wander into the project area, check out "Print" and "Watermills". Those two sections are pretty much done.</p>
      </div>
      {/* <StaticImage
        alt='Psych Fest poster, just the image'
        src='../images/PF22.png'
        placeholder='blurred'
      /> */}
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo />;