import * as React from 'react'
import { footer } from './layout.module.css'
/*import { Link } from 'gatsby'*/

const Footer = () => {
    
    return (
        <footer className={footer}>
            <p>
                &copy; {new Date().getFullYear()} Richard Powell
            </p>
        </footer>
    )
}

export default Footer