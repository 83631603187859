import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { active, container, content, gap, header, /* heading, */ navLinks, navLinkItem, navLinkText, siteTitle, titleRow } from './layout.module.css';
import Footer from './footer';

const Layout = ({ pageTitle, children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className={container}>
            <div className={header}>
                <title>
                    {pageTitle} | {data.site.siteMetadata.title}
                </title>
                <div className={titleRow}>
                    <Link to='/' style={{ textDecoration: "none" }}>
                        <header className={siteTitle}>{data.site.siteMetadata.title}</header>
                    </Link>
                </div>
                <div className={gap}></div>
                <nav>
                    <ul className={navLinks}>
                        {/* <li className={navLinkItem}>
                            <Link to='/' className={navLinkText} activeClassName={active}>
                                Home
                            </Link>
                        </li> */}
                        <li className={navLinkItem}>
                            <Link to='/projects' className={navLinkText} activeClassName={active} partiallyActive={true}>
                                Projects
                            </Link>
                        </li>
                        <li className={navLinkItem}>
                            <Link to='/about' className={navLinkText} activeClassName={active}>
                                About
                            </Link>
                        </li>
                        <li className={navLinkItem}>
                            <Link to='/contact' className={navLinkText} activeClassName={active}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <main className={content}>
                {/* <h1 className={heading}>{pageTitle}</h1> */}
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
